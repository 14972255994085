import React, { ReactElement } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '@/components/layout'

function Page(): ReactElement {
  return (
    <Layout>
      <h1>Om Oss</h1>

      <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 pt-8 pb-0">
        <StaticImage
          src="../images/christer-forland.jpg"
          alt="Christer Førland"
          layout="fullWidth"
          aspectRatio={1}
          objectFit="cover"
          transformOptions={{ grayscale: true }}
          className="rounded-full shadow-md"
          imgClassName="rounded-full"
        />
        <div className="col-span-2 pl-8">
          <h3 className="text-secondary">Christer Førland</h3>
          <ul>
            <li>Autorisert Kiropraktor</li>
            <li>Practitioner of Dynamic Neuromuscular Stabilization (DNSP)</li>
            <li>Motion Palpation Institute Certified Practitioner</li>
            <li>Personlig Trener, Norges Idrettshøyskole</li>
          </ul>
        </div>
      </div>

      <h2>Om oss</h2>
      <p>
        Kiropraktor Akutten drives av offentlig godkjent kiropraktor Christer
        Førland som har 5 års utdannelse ved Scandinavian College of
        Chiropractic (Skandinaviska Kiropraktorhøgskolan) i Stockholm.
      </p>

      <p>
        Kiropraktor Christer Førland startet i 2009 Stavanger Kiropraktikk som
        senere utvidet til Sandnes og skiftet navn til Stavanger-Sandnes
        Kiropraktikk. I juni 2015 startet han Kiropraktor Akutten
        Stavanger/Sandnes.
      </p>

      <h2>Spisskompetanse</h2>
      <p>
        Kiropraktor Christer Førland brenner for å gjøre en så komplett
        behandling som mulig og driver derfor med en kontinuerlig
        videreutdanning. Han har deltatt på en rekke kurs i USA og flere land i
        Europa. Denne videreutdanningen har gitt ham titlene «Practitioner of
        Dynamic Neuromuscular Stabilization» og «Motion Palpation Institute
        certified practitioner».
      </p>

      <p>
        I september 2014 var han den første i Norge som fikk tittelen DNSP
        (practitioner of dynamic neuromuscular stabilization) etter å fullført
        en 3-årig videreutdanning innen rehabilitering. Denne videreutdanningen
        tok han ved Rehabilitation Prague School i Tsjekkia. Dette instituttet
        regnes som en av verdens ledene innen rehabilitering av alle muskel og
        skjelettplager. Behandlingsteknikken dere kalles for DNS (dynamisc
        neuromuscular stabilization) og det er bare et trettitalls terapauter i
        Europa som er sertifisert i DNS behandling. DNS har revolusjonert
        manuell behandling og funksjonell trening på verdensbasis.
      </p>

      <p>
        Kiropraktor Christer Førland er også videreutdannet igjennom Motion
        Palpation Institute (MPI) og har fått tittelen M.P.I. certified
        practitioner som 1 av 3 kiropraktorer i Norge. MPI er et institutt i USA
        som har formet Christer Førland som kiropraktor. Målet deres er å
        kvalitetssikre og videreutdanne kiropraktorer til å kunne gi en så
        effektiv og fullverdig behandling som overhodet mulig. Dette inkluderer
        både muskel og leddbehandling for å kunne gi pasientene en komplett
        behandling fra en og samme terapaut.
      </p>

      <div>
        <h2>Samarbeidspartnere</h2>
        <ul className="grid grid-cols-2">
          <li>
            <a href="http://www.akuttkiropraktikk.no/" target="_blank">
              Akutt Kiropraktikk
            </a>
          </li>
          <li>
            <a
              href="http://www.trondheimkiropraktorklinikk.no/"
              target="_blank"
            >
              Trondheim Kiropraktorklinikk
            </a>
          </li>
          <li>
            <a href="http://www.impulsefysioterapi.no/" target="_blank">
              Impulse Fysioterapi, Trondheim
            </a>
          </li>
          <li>
            <a href="http://porsgrunnkiropraktorklinikk.no/" target="_blank">
              Porsgrunn Kiropraktorklinikk
            </a>
          </li>
          <li>
            <a href="http://porsgrunnkiropraktorklinikk.no/" target="_blank">
              Skien kiropraktorklinikk
            </a>
          </li>
          <li>
            <a href="http://www.kiropractic.no/" target="_blank">
              Kiropractic
            </a>
          </li>
          <li>
            <a href="http://www.ptservice.no/" target="_blank">
              Personlig Trenerservice
            </a>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default Page
